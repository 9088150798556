<template>
  <main>
    <package-header title="Searches &amp; STCs" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">

        <div class="col-lg-2">

          <!-- Package navigation hamburger -->
          <button class="btn btn-secondary d-lg-none mb-2" data-toggle="collapse" data-target="#package-nav" aria-expanded="false" aria-controls="package-nav">
            <svg class="icon icon-menu"><use xlink:href="/icons/symbol-defs.svg#icon-menu"></use></svg>
            <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col">

          <!-- Tabs navigation -->
          <ul class="nav nav-tabs mb-4" role="tablist">
            <li class="nav-item">
              <router-link to="/pages/searches-stc" class="nav-link active">
                Purchase
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/pages/searches-stc/manage-existing" class="nav-link">
                Manage Purchases
              </router-link>
            </li>
          </ul>

          <!-- Main package content -->
          <div class="row">

            <!-- Data entry form -->
            <div class="col-md-8">

              <router-link to="/pages/searches-stc">
                <svg class="icon icon-arrow_cta_back mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
                Return to purchase page
              </router-link>

              <h2 class="mt-4">Review Order Details</h2>
              <p class="lead">Please note: All title searches of a strata title include the related common property record. There is no fee for a common property record or parcel information and miscellaneous notes.</p>

              <p>Post-Search titles are bililed after the package has been received.</p>

              <table class="table table-sm mt-4">
                <thead>
                  <tr>
                    <th class="bg-light" colspan="2">Item 1: Post-Search</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Title Number</td>
                    <td>BT15643</td>
                  </tr>
                  <tr>
                    <td>Land Title District</td>
                    <td>Vancouver</td>
                  </tr>
                  <tr>
                    <td>Title Number</td>
                    <td>BT15643</td>
                  </tr>
                  <tr>
                    <td>Parcel Identifier (PID)</td>
                    <td>006-477-267</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>REGISTERED</td>
                  </tr>
                  <tr>
                    <td>First Owner Name on Title</td>
                    <td>JOHNSON, TIMOTHY CHARLES</td>
                  </tr>
                  <tr>
                    <td><strong>Product Fee</strong></td>
                    <td><strong>$9.45</strong></td>
                  </tr>
                </tbody>
              </table>

              <table class="table table-sm mt-4">
                <thead>
                  <tr>
                    <th class="bg-light" colspan="2">Item 2: Post-Search</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Title Number</td>
                    <td>BT15643</td>
                  </tr>
                  <tr>
                    <td>Land Title District</td>
                    <td>Vancouver</td>
                  </tr>
                  <tr>
                    <td>Parcel Identifier (PID)</td>
                    <td>006-477-267</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>REGISTERED</td>
                  </tr>
                  <tr>
                    <td>First Owner Name on Title</td>
                    <td>JOHNSON, TIMOTHY CHARLES</td>
                  </tr>
                  <tr>
                    <td><strong>Product Fee</strong></td>
                    <td><strong>$9.45</strong></td>
                  </tr>
                </tbody>
              </table>

            </div>

            <div class="col-md-4">
              <div class="card bg-medium border-0 sticky-top">
                <div class="card-body">
                  <h3 class="card-title">Order Summary</h3>

                  <dl class="mt-2">
                    <dt>File Reference</dt>
                    <dd>ABC123</dd>
                  </dl>

                  <div class="d-flex justify-content-between">
                    <span>Product Fee:</span>
                    <span>$9.25</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>Service Charge:</span>
                    <span>$6.00</span>
                  </div>

                  <hr>

                  <div class="d-flex justify-content-between">
                    <span>Subtotal:</span>
                    <span>$15.25</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>GST:</span>
                    <span>$0.32</span>
                  </div>

                  <hr>

                  <div class="d-flex justify-content-between">
                    <strong>Total</strong>
                    <strong>$15.57</strong>
                  </div>

                  <dl class="mt-2">
                    <dt>GST Registration Number</dt>
                    <dd>81630 4414 RT0001</dd>
                  </dl>

                  <button class="btn btn-primary mt-2">Purchase (2)</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Searches &amp; STCs"></help-panel>
  </main>
</template>

<script>

var filters = {
  active: function (tableItems) {
    return tableItems.filter(function (tableItem) {
      return tableItem.selected
    })
  }
}

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      preSearch: false,
      postSearch: false,
      tableItems: [
        {
          a:'1',
          b:'015-671-569 S/1592///A//8',
          c:'KT119399 NE Registered Sm*, P*',
          d:'$6.00',
        },
        {
          a:'2',
          b:'015-671-570 S/1592///A//9',
          c:'KT119372 NE Registered Ar*, J*',
          d:'',
        },
        {
          a:'3',
          b:'015-671-571 S/1592///A//10',
          c:'KT119348 NE Registered Da*, S*',
          d:'',
        },
        {
          a:'4',
          b:'015-671-572 S/1592///A//11',
          c:'KT119321 NE Registered Di*, S*',
          d:'',
        }
      ]
    }
  },
  methods: {

  }
}
</script>

